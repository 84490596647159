<template>
  <div class="text-center">
    <div class="thumbnail-wrapper">
      <img
        class="thumbnail img-fluid"
        :src="data.category_img_url"
        :alt="data.category_title"
        :style="{height: imgContainerHeight + 'px'}"
      />
    </div>
    <p class="title">{{ data.category_title }}</p>
  </div>
</template>

<script>
  export default {
    props : {
      data : {
        type     : Object,
        required : true,
      },
    },
    data() {
      return {
        thumbLoading : true,
        hover        : false,

        imgContainerWidth  : 0,
        imgContainerHeight : 0,
      }
    },
    watch : {
      imgContainerWidth() {
        this.imgContainerHeight = this.imgContainerWidth;// * 0.625; // 16:10
      },
    },
    methods : {

      onThumbLoad() {
        this.thumbLoading = false;
      },

      /**
       * Get Image's Container Width
       */
      getImgContainerWidth() {
        this.$nextTick(() => {
          const $container = this.$el;
          if ($container) this.imgContainerWidth = $container.clientWidth;
        });
      },
    },
    created() {
      window.addEventListener("resize", this.getImgContainerWidth);
    },
    mounted() {
      this.thumbLoading = true;
      this.getImgContainerWidth();
    },
    updated() {
      this.getImgContainerWidth();
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  .skeleton {
    background-color: rgba(0,0,0,0.5);
  }

  .thumbnail-wrapper {
    background-color: black;
    border: 2px solid $primary-color;
    box-shadow: 0 4px 15px 0 rgba(40, 44, 53, 0.06),
      0 2px 2px 0 rgba(40, 44, 53, 0.08);
    overflow: hidden;
  }

  .thumbnail {
    background-color: white;
    object-fit: cover;
    transition: 0.5s transform;
    transform-origin: center center;
    overflow: hidden;
    &:hover {
      overflow: hidden;
      opacity: 0.5;
      transform: scale(1.2);
    }
  }

  .hidden {
    visibility: hidden;
    position: absolute;
    display: inline-block;
  }

  .title {
    margin-top: 0.25rem;
    margin-bottom: 0.75rem;
    color: #fff;
    line-height: 1;
    font-size: 1.3em !important;
    font-weight: normal !important;
  }

  /* Extra Small devices */
  @media only screen and (max-width: $xs-max) {
    .title {
      font-size: 1.1em !important;
    }
  }

  /* Small devices (portrait tablets and large phones) */
  @media only screen and (min-width: $sm-min) {
    .title {
      font-size: 1.3em !important;
    }
  }
  /* Medium devices (landscape tablets) */
  @media only screen and (min-width: $md-min) {
    .title {
      margin-bottom: 2rem;
      font-size: 1.75em !important;
    }
  }
  /* Large devices (laptops/desktops) */
  @media only screen and (min-width: $lg-min) {}
  /* Extra large devices (large laptops and desktops) */
  @media only screen and (min-width: $xl-min) {
    .title {
      font-size: 1.2em !important;
    }
  }
  @media only screen and (min-width: $special) {
    .title {
      font-size: 1.2em !important;
    }
  }
  @media only screen and (min-width: $xl2-min) {
    .title {
      font-size: 1.2em !important;
      margin-bottom: 2.15rem;
    }
  }
  /* iMac Res */
  @media only screen and (min-width: $xl5-min) {
    .title {
      margin-bottom: 3rem;
    }
  }
</style>